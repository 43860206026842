/**
 * Syntax highlighting styles
 */
li {
  .highlighter-rouge {
    margin-top: 0.6rem;
  }
}
.highlight {
  background: #e5e2da !important;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  @extend %vertical-rhythm;

  .highlighter-rouge {
    background: rgb(246, 246, 246);
  }
  .c     { color: #998; font-style: italic } // Comment
  .err   { color: #a61717; background-color: #e3d2d2 } // Error
  .k     { font-weight: bold } // Keyword
  .o     { font-weight: bold } // Operator
  .cm    { color: #998; font-style: italic } // Comment.Multiline
  .cp    { color: #999; font-weight: bold } // Comment.Preproc
  .c1    { color: #998; font-style: italic } // Comment.Single
  .cs    { color: #999; font-weight: bold; font-style: italic } // Comment.Special
  .gd    { color: #000; background-color: #fdd } // Generic.Deleted
  .gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
  .ge    { font-style: italic } // Generic.Emph
  .gr    { color: #a00 } // Generic.Error
  .gh    { color: #999 } // Generic.Heading
  .gi    { color: #000; background-color: #dfd } // Generic.Inserted
  .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
  .go    { color: #888 } // Generic.Output
  .gp    { color: #555 } // Generic.Prompt
  .gs    { font-weight: bold } // Generic.Strong
  .gu    { color: #aaa } // Generic.Subheading
  .gt    { color: #a00 } // Generic.Traceback
  .kc    { font-weight: bold } // Keyword.Constant
  .kd    { font-weight: bold } // Keyword.Declaration
  .kp    { font-weight: bold } // Keyword.Pseudo
  .kr    { font-weight: bold } // Keyword.Reserved
  .kt    { color: #458; font-weight: bold } // Keyword.Type
  .m     { color: #099 } // Literal.Number
  .s     { color: #d14 } // Literal.String
  .na    { color: #008080 } // Name.Attribute
  .nb    { color: #0086B3 } // Name.Builtin
  .nc    { color: #458; font-weight: bold } // Name.Class
  .no    { color: #008080 } // Name.Constant
  .ni    { color: #800080 } // Name.Entity
  .ne    { color: #900; font-weight: bold } // Name.Exception
  .nf    { color: #900; font-weight: bold } // Name.Function
  .nn    { color: #555 } // Name.Namespace
  .nt    { color: #000080 } // Name.Tag
  .nv    { color: #008080 } // Name.Variable
  .ow    { font-weight: bold } // Operator.Word
  .w     { color: #bbb } // Text.Whitespace
  .mf    { color: #099 } // Literal.Number.Float
  .mh    { color: #099 } // Literal.Number.Hex
  .mi    { color: #099 } // Literal.Number.Integer
  .mo    { color: #099 } // Literal.Number.Oct
  .sb    { color: #d14 } // Literal.String.Backtick
  .sc    { color: #d14 } // Literal.String.Char
  .sd    { color: #d14 } // Literal.String.Doc
  .s2    { color: #d14 } // Literal.String.Double
  .se    { color: #d14 } // Literal.String.Escape
  .sh    { color: #d14 } // Literal.String.Heredoc
  .si    { color: #d14 } // Literal.String.Interpol
  .sx    { color: #d14 } // Literal.String.Other
  .sr    { color: #009926 } // Literal.String.Regex
  .s1    { color: #d14 } // Literal.String.Single
  .ss    { color: #990073 } // Literal.String.Symbol
  .bp    { color: #999 } // Name.Builtin.Pseudo
  .vc    { color: #008080 } // Name.Variable.Class
  .vg    { color: #008080 } // Name.Variable.Global
  .vi    { color: #008080 } // Name.Variable.Instance
  .il    { color: #099 } // Literal.Number.Integer.Long
}

blockquote {
  margin: 0;
  padding: 0 1em;
  color: #57606a;
  border-left: 0.25em solid #d0d7de;
  font-size: 16px;
  li {
    margin-bottom: 0.6rem;
    img {
      margin-top: 0.6rem;
    }
  }
}
code.highlighter-rouge {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: #afb8c133;
  border-radius: 6px;
}